<template>
	<div id="Me">
		<div class="userhome">
			<div class="Portrait_box">
				<div class="Portrait">
					<div class="porone">
						<img :src="User.avatar" alt="">
					</div>
					<div class="portwo">
						<p>{{ User.name }}</p>
						<p>手机号：{{ User.mobile }}</p>
					</div>
					<van-uploader  :after-read="afterRead">
						<van-button type="info" size="small" class="addimg" style="color:black !important;color:aliceblue !important;">修改头像</van-button>
					</van-uploader>
				</div>
				<div style="color:white" class="boxbottom">
					<div >
						<p>LV.{{ User.vip_level }}</p>
						<p>会员等级</p>
					</div>
					<div >
						<p>{{ User.invite }}</p>
						<p>邀请码</p>
					</div>
					<div >
						<p>{{ User.bean }}</p>
						<p>账户余额</p>
					</div>
				</div>
			</div>
			<div class="bottombac">
				<!-- <van-field label="Steam_id" :value="User.steam_id" readonly /> -->
				<van-field center clearable label="Steam链接" v-model="steams" v-if="users.steam_url=='' || users.steam_url==null">
					<template #button>
						<van-button size="small" type="info" @click="GetSetSteam_url" style="color:black !important" v-if="users.steam_url=='' || users.steam_url==null">修改</van-button>
					</template>
				</van-field>
				<van-field center clearable label="Steam链接" v-model="User.steam" v-else>
					<template #button>
						<van-button size="small" type="info" @click="GetSetSteam_url" style="color:black !important" v-if="users.steam_url=='' || users.steam_url==null">修改</van-button>
					</template>
				</van-field>
				<van-field center clearable label="昵称" v-model="User.name">
					<template #button>
						<van-button size="small" type="info" @click="GetSetName" style="color:black !important">修改</van-button>
					</template>
				</van-field>
				<!-- <van-field center clearable label="邀请码" v-model="User.invite">
					<template #button>
						<van-button size="small" type="info" @click="GetSetInvite_code" style="color:black !important">修改</van-button>
					</template>
				</van-field> -->
				<van-field center clearable label="绑定邀请码" v-if="inviter_id == 0" v-model="invite_code_bangding">
					<template #button>
						<van-button size="small" type="info" @click="setinvite_code" style="color:black !important">绑定</van-button>
					</template>
				</van-field>
				<van-field center clearable label="已绑定邀请码" v-else>
					<template >
						
					</template>
				</van-field>
				<van-field label="手机号" :value="User.mobile" readonly />
				<h1 class="h1" >
					实名认证
				</h1>
				<van-field center clearable label="姓名" v-model="username" v-if="User.fullname==null">
				</van-field>
				<van-field center clearable label="身份证号" v-model="userid" v-if="User.fullname==null">
					<template #button>
						<van-button size="small" type="info" @click="getuserid" style="color:black !important">认证</van-button>
					</template>
				</van-field>

				<div v-else style="color:white;margin-left:10px" >
					已实名认证
				</div>
			</div>
		</div>
		<div class="backs" @click="backs()">
			退出登录
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		ImageBase64,
		SetAvatar,
		PersonalInfo,
		SetSteam_url,
		SetName,
		SetInvite_code,
		checkidcards,
		set_inviter
	} from '@/network/api.js'
	export default {
		name: 'Me',
		data() {
			return {
				users:'',
				username:'',
				userid:'',
				inviter_id:0,
				invite_code_bangding:'',
				steams:''
			}
		},
		created() {
			setTimeout(() => {
				console.log(this.User);
			}, 500);
		},
		mounted(){
			this.GetPersonalInfo()
		},
		methods: {
			backs(){
				localStorage.clear();
				sessionStorage.clear();
				this.$router.push('/home')

				setTimeout(() => {
				this.$router.go(0);

				}, 500);
			},
			setinvite_code(){
				set_inviter(this.invite_code_bangding).then(res => {
					this.$notify({
						message: res.data.message,
						type: 'success'
					})
					this.GetPersonalInfo()
				})
			},
			getuserid(){
				console.log(this.userid,this.username);
				let data={
					fullname:this.username,
					certificate:this.userid
				}
				checkidcards(data).then((res)=>{
					this.$notify({
						type: 'success',
						message: res.data.message
					})
				})
			},
			//修改头像
			afterRead(file) {
				if(this.users.vip_level>=5){
					// console.log(1);
					ImageBase64(file.content).then((res) => {
						SetAvatar(res.data.data.url).then((res) => {
							this.GetPersonalInfo()
						})
					})
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				}else{
					this.$notify({
						type: 'error',
						message: 'VIP5级以上修改'
					})
				}
				
			},

			//修改steam链接
			GetSetSteam_url(){
				SetSteam_url(this.steams).then((res) => {
					// console.log(res)
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				})
			},

			//修改昵称
			GetSetName(){
				SetName(this.User.name).then((res) => {
					// console.log(res)
					this.GetPersonalInfo()
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				})
			},

			//修改推广码
			GetSetInvite_code(){
				SetInvite_code(this.User.invite).then((res) => {
					// console.log(res)
					this.GetPersonalInfo()
					this.$notify({
						type: 'success',
						message: '修改成功'
					})
				})
			},

			//更新个人信息
			GetPersonalInfo() {
				
				PersonalInfo().then((res) => {
					this.inviter_id = res.data.message.inviter_id
					this.PostUser(res.data.message)
					this.users=res.data.message
					console.log(this.users);
				})
			},

			...mapMutations([
				'PostUser'
			])
		},
		computed: {
			...mapState([
				'User'
			])
		}
	}
</script>

<style scoped lang="scss">
::v-deep .van-cell__title{
	color: white !important;
}
::v-deep .van-cell__value{
	color: white !important;
}
::v-deep .van-field__control{
	color: white !important;
}
.userhome{
	background: url('../../assets/images/985/me/1.png') no-repeat center;
	background-size: 100% 100%;
	padding-top: 30px;
	margin-top: -30px;
}
.backs{
	width: 40%;
	height: 50px;
	margin: 50px auto;
	color: #fff;
	text-align: center;
	line-height: 50px;
	font-size: 16px;
	background: url('../../assets/images/985/me/2.png') no-repeat center;
	background-size: 100% 100%;

}
.bottombac{
	// background: url('../../assets/images/q2/me/top.png') no-repeat center !important;
	background-size: 100% 100% !important;
	padding: 10px;
	padding-bottom: 30px;
	width: 350px;
	
}
.addimg::before{
	color: #fff !important;
}
.addimg{
	background: url('../../assets/images/q2/me/xiu.png') no-repeat center !important;
	background-size: 100% 100% !important;
	color: white !important;
	padding: 10px !important;
}
.boxbottom{
	display: flex;
	justify-content: center;
	justify-content: space-evenly;
	div{
		background: url('../../assets/images/985/me/active.png') no-repeat center;
		background-size: 100% 100%;
		padding: 10px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.h1{
	color: white;
	padding: 10px;
	margin-top: .5rem;
}
	#Me {
		width: 100%;
		padding:10px 10px 40px;
		box-sizing: border-box;

		.Portrait_box {
			width: 100%;
			text-align: center;
			// background: url('../../assets/images/q2/me/top.png') no-repeat center;
			background-size: 100% 100%;
			padding-bottom: 20px;
			.Portrait {
				width: 80%;
				height: 100px;
				// border-radius: 50%;
				overflow: hidden;
				margin-right: 50px;
				margin: 10px auto;
				display: flex;
				flex-wrap: nowrap;
				
				align-items: center;
				justify-content: space-around;
				.porone{
					width: 50px;
					height: 50px;
					img {
						width: 100%;
						height: 100%;
						border: 1px solid #ccc;
						padding: 1px;
						// vertical-align: middle;
					}
				}
				.portwo{
					color: white;
					p{
						text-align: left;
					}
					p:nth-child(1) {
						font-size: 14px;
					}
					p:nth-child(2) {
						font-size: 10px;
						color: #ccccccd2;
					}
				}
			}
		}

		.van-cell {
			background: none;
			margin-top: 10px;

			.van-field__label {
				width: 75px;
				color: #fff;
				font-size: 12px;
			}

			.van-field__control {
				color: #fff;
			}
		}

		.van-cell::after {
			background: #fff;
		}
	}
</style>
